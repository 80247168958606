import React from "react";
import logo from '../images/vattaraLogobottom.png'
import store from '../images/playstore.png'
import mail from '../images/mailFooter.png'
import add from '../images/footerAddress.png'
import phone from '../images/phoneFooter.png'
import x from '../images/footerX.png'
import fb from '../images/FooterFB.png'
import ins from '../images/FooterIns.png'
import chat from '../images/chatRating.png'
import zeal from '../images/zeal_logo.png'
import { useState } from "react";

import { Box, Modal, } from '@mui/material';
import RateingBox from "../RateingBox/RateingBox";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";



const Footer: React.FC = () => {
    const loginData = useSelector((state: any) => state.userData.userLoginData);
    console.log("fnal", loginData)
    const gradientStyle = {
        background: 'linear-gradient(90deg, #1063b5,#02a7e4, #0cb7d1)',
        height: '70px',
        width: '100%'
    };
    const [open, setOpen] = useState<any>(false);
    const handleClose = () => {
        // console.log("jheki");

        setOpen(false);
    }
    function handleRate() {
        setOpen(true)
    }
    const Navigate = useNavigate()

    function scrollToTop(value: any) {
        console.log("work")
        window.scrollTo({
            top: value,
            behavior: 'smooth',
        });
    }

    function handlePost() {

        if (loginData.phone == "" && loginData.name == "" && loginData.email == "" || loginData == null) {
            Navigate('/loginPost')
        }
        else if (loginData.category === "" || loginData.category === null) {
            Navigate('/loginPostList')
        }
        else if (loginData.name !== "" && loginData.category !== "" && loginData.category !== null) {
            Navigate("/PostForm");

        }

    }

    function handleList() {

        if (loginData.name !== "" && loginData.category !== "" && loginData.category !== null) {
            Navigate("/list");

        } else {
            Navigate('/loginPost')
        }

    }

   


    return (
        <div className="flex flex-col justify-center">
            <div className=" msm:mt-[%] lg:mt- bg-[#F5F4F8]">
                <footer className=" lg:mx-[0%] sm:ml-2">
                    <div className="mx-auto w-full max-w-screen-xl  lg:p-4 sm:p-2 lg:py-8">
                        <div className="lg:flex sm:flex sm:gap-3">
                            <div className="mb-6 md:mb-0 msm:flex msm:justify-center msm:items-center msm:gap-5 sm:block lg:block">
                                <div className="flex  items-center">
                                    <img
                                        src={logo}
                                        className="w-26 h-16"
                                        alt="Vattara Logo"
                                    />

                                </div>
                                <div className="border-1 sm:hidden border-black h-20 mt-4 opacity-30 msm:flex lg:hidden"></div>
                                <div>
                                    <p className="font-semibold mt-4 cursor-pointer">Download Our App</p>
                                    <div className="mt-2">
                                        <img src={store} className="w-32 lg:w-40" />
                                    </div>
                                    <div onClick={handleRate} className="flex mt-2 gap-2 underline">
                                        <img src={chat} />
                                        <p className="font-semibold cursor-pointer">Rate Us Now</p>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:flex sm:flex   lg:justify-between msm:ml-10 sm:gap-3 sm:ml-3 lg:ml-30 lg:gap-32" >
                                <div>
                                    <h2 className="lg:mb-6 msm:mb-4 text-lg font-bold   ">
                                        Quick Links
                                    </h2>
                                    <div className=" font-medium cursor-pointer">

                                        <Link to={'/'}>
                                            <p onClick={() => scrollToTop(0)} className="hover:underline ">
                                                Home
                                            </p>
                                        </Link>
                                        
                                            <p onClick={() => Navigate("/services")}  className="hover:underline mt-2">
                                                Services
                                            </p>
                                       


                                        <p onClick={() => Navigate("/profile")} className="hover:underline mt-2"> 
                                            Contact Us
                                        </p>

                                       
                                            <p onClick={() => Navigate("/career")}  className="hover:underline mt-2">
                                                Careers
                                            </p>
                                  

                                        <p className="hover:underline mt-2">
                                            FAQs
                                        </p>


                                    </div>
                                </div>
                                <div className="msm:mt-6 sm:mt-0 lg:mt-0 ">
                                    <h2 className="lg:mb-6 msm:mb-4 text-lg  font-bold">
                                        Services
                                    </h2>
                                    <div className="font-medium ">


                                        <p onClick={() => scrollToTop(0)} className="hover:underline cursor-pointer">
                                            Home
                                        </p>



                                        <p onClick={handleList} className="hover:underline cursor-pointer mt-2">
                                            Property Listings
                                        </p>


                                        <p onClick={handlePost} className="hover:underline cursor-pointer mt-2">
                                            Post Your Property
                                        </p>

                                        
                                            <p  onClick={() => Navigate("/Subscription")}  className="hover:underline cursor-pointer mt-2">
                                                Subscription Plans
                                            </p>
                                    

                                      
                                            <p onClick={() => Navigate("/helpCenter")}  className="hover:underline cursor-pointer mt-2">
                                                Help Center
                                            </p>
                                     

                                        <Link to={'/'}>
                                            <p onClick={() => scrollToTop(700)} className="hover:underline cursor-pointer mt-2">
                                                Featured Properties
                                            </p>
                                        </Link>

                                    </div>
                                </div>
                                <div className="lg:mt-0 sm:mt-0 msm:mt-6 sm:ml-2">
                                    <h2 className="lg:mb-6 msm:mb-4 text-lg font-bold">
                                        Contact Us
                                    </h2>
                                    <div className="">
                                        <div>
                                            <div className="flex gap-2 items-center " >
                                                <img src={mail} />
                                                <p className="font-medium">support@vattara.com</p>
                                            </div>
                                            <div className="flex gap-2 items-center mt-4" >
                                                <img src={phone} />
                                                <p className="font-medium">(+91) 9444995995</p>
                                            </div>
                                            <div className="flex gap-2 items-start mt-4" >
                                                <img src={add} className="mt-2" />
                                                <div>
                                                    <p className="font-medium">Vellore</p>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="lg:mt-0 msm:mt-6 sm:mt-0 msm:flex msm:gap-4 sm:block lg:block sm:ml-2">
                                    <div>
                                        <h2 className="lg:mb-6 msm:mb-4 text-lg  font-bold ">
                                            Legal
                                        </h2>


                                        <div>
                                          <p onClick={() => Navigate("/terms")} className="hover:underline font-medium">Terms and Conditions</p>
                                             <p onClick={() => Navigate("/privacy")} className="hover:underline mt-2 font-medium">Privacy Policy</p>
                                        </div>
                                    </div>
                                    <div className="border-1 border-black h-24 mt-2 opacity-30 msm:flex sm:hidden lg:hidden"></div>
                                    <div>
                                        <h2 className="mb-2 text-lg sm:mt-4 font-bold lg:mt-4">Follow us</h2>


                                        <div className="flex gap-2">
                                            <img src={fb} />
                                            <img src={ins} />
                                            <img src={x} />

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                </footer>
            </div>

            <div className="msm:pb-[32%] sm:pb-0 lg:pb-0 sm:mb-0 lg:mb-0" style={gradientStyle}>
                <div className="lg:mx-[20%] mt-3 sm:flex sm:mx-4 lg:flex justify-between items-center">
                    <div className="">
                        <p className="text-white text-center msm:text-xs lg:text-sm">© 2024 Vattara Solutions Pvt Ltd. All rights reserved.</p>
                    </div>
                    <a href="https://zealzoft.com/" target="_blank" rel="noopener noreferrer">
                        <div className="flex lg:gap-2 msm:gap-2 msm:justify-center items-center msm:mx-2">
                            <p className="text-white lg:text-base msm:text-xs">Developed and Designed by </p>
                            <img src={zeal} className="lg:w-40 msm:w-32" />
                        </div>
                    </a>
                </div>
            </div>
            <div>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="contact-modal-title"
                    aria-describedby="contact-modal-description"
                >
                    <Box >
                        <div className=''>
                            <RateingBox setOpen={setOpen} />
                        </div>
                    </Box>
                </Modal>

            </div>
        </div>
    );
};

export default Footer;

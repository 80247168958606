import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
    const loginData = useSelector((state: any) => state.userData.userLoginData);

    
    const isAuthenticated = loginData && loginData.user_id !== undefined && loginData.name !== "";

   
    if (!isAuthenticated) {
        return <Navigate to='/contact' />;
    }

    
    return <Outlet />;
};

export default PrivateRoute;
